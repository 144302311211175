import getErrorLocalePathMessage from "~/utils/locale";
import AuthAPI from "~/services/auth";
import useAsync from "~/hooks/use-async";

export default function useSSOLogin(options?: { onSuccess?: () => void; onError?: (error: Error) => void }) {
  const toaster = useToast();
  const { t, te } = useI18n();

  const onSocialLoginError = (error: Error) => {
    const err = error as Error & { code?: string; token: string; provider: TAuthProvider };

    if (options?.onError) {
      options.onError(error);
      return;
    }

    if (err.code === "device_limit_exceeded")
      onDeviceLimitError(err.token, err.provider);
    const { message } = getErrorLocalePathMessage(err.message, { t, te });
    toaster.error(message);
  };
  const onSocialLoginSuccess = () => {
    if (options?.onSuccess)
      options.onSuccess();
  };

  const {
    run: executeSocialLogin,
    loading: socialLoginLoading,
  } = useAsync(AuthAPI.signinWithProvider, { onSuccess: onSocialLoginSuccess, onError: onSocialLoginError });

  return {
    executeSocialLogin,
    socialLoginLoading,
  };
}
